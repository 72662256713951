// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-horse-ratings-js": () => import("./../src/pages/horseRatings.js" /* webpackChunkName: "component---src-pages-horse-ratings-js" */),
  "component---src-pages-horses-js": () => import("./../src/pages/horses.js" /* webpackChunkName: "component---src-pages-horses-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mlb-js": () => import("./../src/pages/mlb.js" /* webpackChunkName: "component---src-pages-mlb-js" */),
  "component---src-pages-nba-js": () => import("./../src/pages/nba.js" /* webpackChunkName: "component---src-pages-nba-js" */),
  "component---src-pages-ncaab-js": () => import("./../src/pages/ncaab.js" /* webpackChunkName: "component---src-pages-ncaab-js" */),
  "component---src-pages-nfl-js": () => import("./../src/pages/nfl.js" /* webpackChunkName: "component---src-pages-nfl-js" */),
  "component---src-pages-publications-js": () => import("./../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

